import React from "react";
import BottomBanier from "../components/ui/BottomBanier";
import Layout from "../components/layout/Layout";
import TopBanier from "../components/ui/TopBanier";

const Activity = () => (
  <Layout pageTitle="Activities">
    <TopBanier />
    <p>This is activity page</p>
    <BottomBanier />
  </Layout>
);

export default Activity;
